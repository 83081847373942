// ATTN we use these constants in data dump
const abTests = {
  // CRO tests
  tyHideAddedProduct: 'ty:hideAddedProduct', // CRO-270
  paymentQueueEditPopup: 'payment:queueEditPopup', // CRO-338 A version win
  upgradeConfirmationPopupRedesign: 'upgrade:confirmationPopupRedesign', // CRO-375 A version win
  quizDesignUpdate: 'quiz:designUpdateV2', // CRO-379 A version win
  tySkipQueueStep: 'ty:skipQueueStep', // CRO-384 A version win
  paymentOrderPreviewDropdown: 'payment:orderPreviewDropdown', // CRO-417
  globalSubscribeBannerUpdate: 'global:subscribeBannerUpdateV2', // CRO-488
  queuePageRedesign: 'queue:pageRedesign', // CRO-476
  scentProfileApplePay: 'scentProfile:applePay', // CRO-445
  popupHidePopups: 'popup:hidePopups', // CRO-475
  homeWhatYouGet: 'home:whatYouGet', // CRO-393
  heroServiceAndOfferDescriptionDivision: 'home:heroServiceAndOfferDescriptionDivision', // CRO-402
  // EP tests
  upgradeEntryPoints: 'upgradeEntryPoints', // EP-2237 B version win
  navScentHoroscopeInPerfumes: 'nav:scentHoroscopeInPerfumes', // EP-2364 A version win
  caseUpsellOptions12vs3: 'caseUpsellOptions12vs3', // EP-2419 A version win
  tyUgradeBlock: 'ty:upgradeBlock', // EP-2916
  // PF tests
  upchargeInfoPayment: 'UpchargeInfoPayment', // PF-1397 A version win
  limitedDropsCheckoutRedirect: 'limitedDropsCheckoutRedirect', // PF-2107 A version win
  recommendedForYouNavLink: 'nav:recommendedForYouNavLink', // PF-2295 A version win
  theValentinesEdit: 'theValentinesEdit', // PF-2316 A version win
  theLuxeEdit: 'theLuxeEditV2', // PF-2354 A version win
  queueDragTooltip: 'queueDragTooltip', // PF-2299 A version win
  constructorRecommendations: 'constructorRecommendations', // PF-2319
} as const

export type AbTestName = typeof abTests[keyof typeof abTests]


export default abTests
