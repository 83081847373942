export default {
  shippingFormValues: 'shippingFormValues',
  authGender: 'authGender',
  authEmail: 'authEmail',
  quizFlowSteps: 'quizFlowSteps',
  claimedGiftCard: 'claimedGiftCard',
  queuePendingProducts: 'queuePendingProducts',
  queueHighPriorityProducts: 'queueHighPriorityProducts',
  queueLowPriorityProducts: 'queueLowPriorityProducts',
  stockNotificationSubscriptionProductUid: 'stockNotificationSubscriptionProductUid',
  lastAddedToCartProductMetadata: 'lastAddedToCartProductMetadata',
  iterableOptInPendingProjects: 'iterableOptInPendingProjects',
  lastAnalyticsPageEventTime: 'lastAnalyticsPageEventTime',
  giftWithSpendThresholdShown: 'giftWithSpendThresholdShown',
  cartSubscriptionBanner: 'isCartSubscriptionBannerShown',
  trackedExperiments: 'trackedExperiments',
  constructorRecommendations: 'constructorRecommendations',
}
