import dayjs from 'date'

import messages from './messages'

// ATTN if you add new validator, then add unit test for it!

// Regular expressions
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
const passwordRegex = /^[!@#$%^&*()_+-=A-Za-z0-9]+$/
const zipCodeRegexp = /^\d{5}([-\s]\d{4})?$/
const canadaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
const militaryZipCodeRegex = /^(340|090|091|092|093|094|095|096|097|098|962|963|964|965|966)\d{2}$/
const militaryStateRegex = /^(AA|AE|AP)$/i
const telephoneRegex = /^\+1\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/

// Helpers
export const isEmpty = (value: any): boolean => (
  typeof value === 'undefined'
  || value === null
  || value === ''
  || /^\s+$/.test(value)
)

const isValueLengthLessThan = (length: number, value: string | number): boolean => (
  !isEmpty(value) && String(value).trim().length < length
)

const isValueLengthLongerThan = (length: number, value: string | number): boolean => (
  !isEmpty(value) && String(value).trim().length > length
)

const isDateNotValid = (value: string): boolean => (
  !isEmpty(value) && !dayjs(value).isValid()
)

const isDateFromPast = (value: string): boolean => (
  !isEmpty(value) && dayjs(value).isBefore(new Date())
)

const isDateToday = (value: string): boolean => (
  !isEmpty(value) && dayjs(value).diff(new Date(), 'day') === 0
)

export const isFieldValueNotEqual = (fieldName: string) => (
  (value: string, values: object): boolean => {
    const otherValue = values[fieldName]?.state?.value

    if (value && otherValue) {
      return value !== otherValue
    }
  }
)

// Helpers
type WhenCondition<Fields> = (values: Fields) => boolean
type WhenValidators = Array<(value: any, fields: any) => any>

// helps add conditional validators
export const when = <T extends any>(condition: WhenCondition<T>, validators: WhenValidators) => (value: any, fields: any) => {
  const values = Object.keys(fields).reduce((result, key) => {
    result[key] = fields[key].state.value
    return result
  }, {} as T)

  if (condition(values)) {
    let error

    validators.some((validator) => {
      error = validator(value, fields)

      if (error) {
        return true
      }
    })

    return error
  }
}

// Validators
export const customRequired = (message: Intl.Message) => (value: any) => {
  if (isEmpty(value)) {
    return message
  }
}

export const required = customRequired(messages.required)

export const minLength = (length: number) => (value: string | number) => {
  if (isValueLengthLessThan(length, value)) {
    return {
      ...messages.minLength,
      values: { length },
    }
  }
}

export const maxLength = (length: number) => (value: string | number) => {
  if (isValueLengthLongerThan(length, value)) {
    return {
      ...messages.maxLength,
      values: { length },
    }
  }
}

export const email = (value: string) => {
  if (!isEmpty(value) && !emailRegex.test(value)) {
    return messages.email
  }
}

export const password = (value: string) => {
  if (!isEmpty(value) && !passwordRegex.test(value)) {
    return messages.password
  }
}

export const birthdayDate = (value: string) => {
  if (isDateNotValid(value)) {
    return messages.date
  }

  if (!isEmpty(value)) {
    // workaround to fix 0010 years, because dayjs parses them as 19xx or 20xx
    if (value.match(/\/0{1,3}\d+$/)) {
      return messages.birthdayDate
    }

    // ATTN pass as param if required
    const date = dayjs(value, 'MM/DD/YYYY')

    if (date.isAfter(new Date())) {
      return messages.pastDate
    }

    if (date.isBefore(dayjs('1907-01-01'))) {
      return messages.birthdayDate
    }
  }
}

export const futureDate = (value: string) => {
  if (isDateNotValid(value)) {
    return messages.date
  }

  if (isDateFromPast(value)) {
    return messages.futureDate
  }
}

export const notToday = (value: string) => {
  if (isDateNotValid(value)) {
    return messages.date
  }

  if (isDateToday(value)) {
    return messages.notTodayDate
  }
}

export const date = (value: string) => {
  if (isDateNotValid(value)) {
    return messages.date
  }
}

export const equal = (fieldName: string, errorMessage: Intl.Message) => (
  (value: string, values: object) => {
    if (isFieldValueNotEqual(fieldName)(value, values)) {
      return errorMessage
    }
  }
)

export const passwordMatch = (fieldName: string) => equal(fieldName, messages.passwordMatch)

export const userName = (value: string) => {
  const disallowed = /([<>\\/])/i

  if (disallowed.test(value)) {
    return messages.userName
  }
}

export const streetAddress = (value: string) => {
  if (!isEmpty(value) && (/^[A-Za-z]+$/.test(value) || /^[\d\s]+$/.test(value) || !/^[\s\p{Script=Latin}0-9#&.'/-]+$/ui.test(value))) {
    return messages.streetAddress
  }
}

export const zipCode = (value: string) => {
  if (!isEmpty(value) && !zipCodeRegexp.test(value)) {
    return messages.zipCode
  }
}

export const canadaPostalCode = (value: string) => {
  if (!isEmpty(value) && !canadaPostalCodeRegex.test(value)) {
    return messages.postalCode
  }
}

export const nonMilitaryZipCode = (value: string) => {
  if (militaryZipCodeRegex.test(value)) {
    return messages.nonMilitary
  }
}

export const nonMilitaryState = (value: string) => {
  if (militaryStateRegex.test(value)) {
    return messages.nonMilitary
  }
}

export const universalPostalCode = (value) => {
  if (!zipCodeRegexp.test(value) && !canadaPostalCodeRegex.test(value)) {
    return messages.postalCode
  }
}

export const phone = (value: string) => {
  if (!isEmpty(value) && !telephoneRegex.test(value)) {
    return messages.phone
  }
}

export const requiredRating = (value: string | number) => {
  const number = Number(value)

  if (!number || number < 1 || number > 5) {
    return messages.required
  }
}

export const requiredToBeChecked = (value: boolean) => {
  if (value !== true) {
    return messages.required
  }
}
